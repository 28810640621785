@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background-color: #1f0d5f !important;
}
/* react-calendar design */
.bk-cln {
    width: 100%;
    padding: 10px;
    background: #ffffff; /* Change background color to #1f0d5f */
    border-radius: 5px;
}

.bk-cln .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
    position: relative;
    display: flex;
}

.bk-cln .react-calendar .react-calendar__navigation__prev-button {
    flex-grow: 2;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bk-cln .react-calendar .react-calendar__navigation__prev-button:disabled svg g path {
    stroke: rgb(209, 221, 232);
}

.bk-cln .react-calendar .react-calendar__navigation__next-button {
    flex-grow: 2;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bk-cln .react-calendar .react-calendar__navigation__label {
    font-size: 20px;
    width: auto;
    padding: 0px 5px;
    font-weight: bold;
    color: rgb(0, 118, 255) !important;
}

.bk-cln .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    text-transform: uppercase;
    font-size: max(0.8vw, 12px);
}

.bk-cln .react-calendar .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    padding: 10px 0px;
    font-weight: 400;
    font-size: 9px;
}

.bk-cln .react-calendar .react-calendar__tile {
    padding: 15px 0px;
    font-weight: 500;
    font-size: max(0.8vw, 16px);
}

.bk-cln .react-calendar button {
    background-color: white;
    border: 0px;
    color: rgb(27, 36, 47);
    cursor: pointer;
}

.bk-cln .react-calendar button:disabled abbr {
    color: rgb(209, 221, 232);
}

.bk-cln .react-calendar .react-calendar__tile--active {
    position: relative;
}

.bk-cln .react-calendar .react-calendar__tile--active::before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: rgb(0, 118, 255);
    color: rgb(255, 255, 255);
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.bk-cln .react-calendar .react-calendar__tile--active abbr {
    color: rgb(255, 255, 255);
    z-index: 2;
    position: relative;
}

.bk-cln .react-calendar .react-calendar__month-view__days {
    display: flex;
    flex-wrap: wrap;
}

.bk-cln .react-calendar .react-calendar__month-view__days__day--neighboringMonth abbr {
    color: rgb(209, 221, 232);
}

/* custom phone */
.custom-phone {
    width: 100%;
}

.custom-phone .form-control {
    height: 57px !important;
    width: 100% !important;
    border-radius: 0px !important;
    border: 1px solid #e5e7eb !important;
    border-top: 0px solid !important;
    padding-left: 70px !important;
}

.custom-phone .selected-flag {
    width: 62px !important;
}

.custom-phone .selected-flag .flag {
    left: 21px;
}

.custom-phone .flag-dropdown {
    background-color: #fff !important;
    border: 1px solid #e5e7eb !important;
    border-top: 0px solid !important;
    border-radius: 3px 0 0 3px;
}
